var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"center","justify-md":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary","small":"","to":"/main/kategori/create"}},[_vm._v(" "+_vm._s(_vm.$_strings.categories.CREATE_CATEGORY_BUTTON_LABEL)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('MasterDataCsvUpload',{attrs:{"importTypeProp":_vm.$_strings.importType.CATEGORY,"uploadCsvProp":_vm.uploadCsv,"downloadTemplateCsvProp":_vm.downloadTemplateCsv,"dataTypeProp":_vm.$_strings.importType.CATEGORY},on:{"fetchList":_vm.fetchCategories}})],1),_c('v-col',{staticClass:"ml-md-auto",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.categories.SEARCH_CATEGORY_INPUT_PLACEHOLDER},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('br'),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"calculate-widths":"","items":_vm.items,"expanded":_vm.expanded,"item-key":"name","show-expand":"","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': _vm.$_item_per_page,
      disablePagination: _vm.isLoading,
      disableItemsPerPage: _vm.isLoading
    }},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function (ref) {
        var value = ref.value;
        var item = ref.item;

        return value && _vm.fetchSubCategories(item);
},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])]}},{key:"item.actionColumn",fn:function(ref){
    var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-btn',{attrs:{"color":"primary lighten-1","small":"","to":("/main/kategori/edit/" + (item.id))}},[_vm._v(" "+_vm._s(_vm.$_strings.common.EDIT)+" ")]):_vm._e()]}},(!_vm.isMobile)?{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var subItem = ref.item;
return [(subItem.subCategoryLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(subItem.subCategories)?[_c('td'),_c('td',_vm._l((subItem.subCategories),function(item){return _c('p',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('td',[(!subItem.subCategories.length)?_c('p',{staticClass:"red--text text-center"},[_vm._v(" Tidak ada data sub kategori ")]):_vm._e(),_vm._l((subItem.subCategories),function(item){return _c('p',{key:item.id},[_vm._v(" "+_vm._s(item.type)+" ")])})],2),_c('td',_vm._l((subItem.subCategories),function(item){return _c('div',{key:item.id},[_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])])}),0),_c('td')]:_vm._e()]}}:null,(_vm.isMobile)?{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('div',{staticClass:"pa-1"},[_c('v-expansion-panels',_vm._l((items),function(item,index){return _c('v-expansion-panel',{key:index,on:{"change":function($event){return _vm.fetchSubCategories(item)}}},[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-expansion-panel-content',[(item.subCategoryLoading)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1):_c('div',[_vm._l((item.subCategories),function(subCategory,index){return _c('div',{key:index},[_c('span',[_vm._v(" "+_vm._s(subCategory.name)+" ")])])}),(_vm.userAccess.canUpdate)?_c('v-btn',{attrs:{"color":"primary lighten-1","small":"","to":("/main/kategori/edit/" + (item.id))}},[_vm._v(" "+_vm._s(_vm.$_strings.common.EDIT)+" ")]):_vm._e()],2)],1)],1)}),1)],1)]}}:null,{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.categories.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }