<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      justify-md="center"
    >
      <v-col cols="auto">
        <v-btn
          color="primary"
          small
          to="/main/kategori/create"
          v-if="userAccess.canCreate"
        >
          {{$_strings.categories.CREATE_CATEGORY_BUTTON_LABEL}}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <MasterDataCsvUpload
          :importTypeProp="$_strings.importType.CATEGORY"
          :uploadCsvProp="uploadCsv"
          :downloadTemplateCsvProp="downloadTemplateCsv"
          :dataTypeProp="$_strings.importType.CATEGORY"
          @fetchList="fetchCategories"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="ml-md-auto"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.categories.SEARCH_CATEGORY_INPUT_PLACEHOLDER"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      calculate-widths
      :items="items"
      :expanded.sync="expanded"
      item-key="name"
      show-expand
      :server-items-length="totalItems"
      @item-expanded="({value, item}) => value && fetchSubCategories(item)"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page,
        disablePagination: isLoading,
        disableItemsPerPage: isLoading
      }"
    >
      <template v-slot:[`item.createdBy`]="{item}">
        <span>
          {{item.createdBy}}
        </span>
        <br>
        <span class="text-caption">
          {{dateFormat(item.createdAt)}}
        </span>
      </template>
      <template v-slot:[`item.actionColumn`]="{item}">
        <v-btn
          color="primary lighten-1"
          small
          :to="`/main/kategori/edit/${item.id}`"
          v-if="userAccess.canUpdate"
        >
          {{$_strings.common.EDIT}}
        </v-btn>
      </template>
      <template
        v-if="!isMobile"
        v-slot:expanded-item="{ headers, item: subItem }"
      >
        <template v-if="subItem.subCategoryLoading">
          <td :colspan="headers.length">
            <v-row justify="center">
              <v-col cols="auto">
                <v-progress-circular
                  class="mx-auto"
                  color="primary"
                  size="30"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-else-if="subItem.subCategories">
          <td></td>
          <td>
            <p v-for="item in subItem.subCategories" :key="item.id">
              {{item.name}}
            </p>
          </td>
          <td>
            <p class="red--text text-center" v-if="!subItem.subCategories.length">
              Tidak ada data sub kategori
            </p>
            <p v-for="item in subItem.subCategories" :key="item.id">
              {{item.type}}
            </p>
          </td>
          <td>
            <div v-for="item in subItem.subCategories" :key="item.id">
              <span>
                {{item.createdBy}}
              </span>
              <br>
              <span class="text-caption">
                {{dateFormat(item.createdAt)}}
              </span>
            </div>
          </td>
          <td></td>
        </template>
      </template>
      <!-- row for mobile view -->
      <template
        v-if="isMobile"
        v-slot:body="{items}"
      >
        <div class="pa-1">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, index) in items"
              :key="index"
              @change="fetchSubCategories(item)"
            >
              <v-expansion-panel-header class="text-subtitle-2">
                {{item.name}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  justify="center"
                  v-if="item.subCategoryLoading"
                >
                  <v-col cols="auto">
                    <v-progress-circular
                      class="mx-auto"
                      color="primary"
                      size="30"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <div v-else>
                  <div
                    v-for="(subCategory, index) in item.subCategories"
                    :key="index"
                  >
                    <span>
                      {{subCategory.name}}
                    </span>
                  </div>
                  <v-btn
                    color="primary lighten-1"
                    small
                    :to="`/main/kategori/edit/${item.id}`"
                    v-if="userAccess.canUpdate"
                  >
                    {{$_strings.common.EDIT}}
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <!-- {{`${$_strings.transportTypes.PAGE_NAME} ${props.pageStart}-${props.pageStop} of ${$props.itemsLength}`}} -->
        <span>
          {{$_strings.categories.PAGE_NAME}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const MasterDataCsvUpload = () => import(/* webpackChunkName: "MasterDataCsvUpload" */ '../Common/MasterDataCsvUpload');

export default {
  name: 'CategoryList',
  components: {
    MasterDataCsvUpload,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.categories.CATEGORY_NAME_TABLE_HEADER_LABEL,
          value: 'name',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.categories.CATEGORY_TYPE_TABLE_HEADER_LABEL,
          value: 'type',
          class: 'white--text primary text-capitalize',
          sortable: false,
          // width: '15vw',
        },
        {
          text: this.$_strings.common.CREATED_BY_TABLE_HEADER_LABEL,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          // width: '15vw',
        },
        {
          text: '',
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          // width: '8vw',
          sortable: false,
        },
      ],
      expanded: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
      searchText: this.$route.query.search || '',
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchCategories();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    search() {
      const { search } = this.$route.query;
      if (search !== this.searchText) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchCategories();
      }
    },
    async fetchCategories() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'createdAt,desc', sortBy, sortDesc }),
        name: this.searchText,
      };
      try {
        this.expanded = [];
        this.isLoading = true;
        const res = await this.$_services.categories.fetchList(this.skipEmptyStringObject(filters));
        const contents = res.data.contents.map((i) => ({ ...i, type: 'Kategori', subCategoryLoading: false }));
        this.items = contents;
        this.totalItems = res.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSubCategories(category) {
      if (category.subCategories) return;
      category.subCategoryLoading = true;
      const {
        page, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: 50,
        sort: 'name,asc',
      };
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      try {
        const res = await this.$_services.categories.fetchSubCategoryList(category.id, filters);
        if (res.data.contents) {
          const contents = res.data.contents.map((i) => ({ ...i, type: 'Sub Kategori' }));
          this.$set(category, 'subCategories', contents);
        }
      } finally {
        category.subCategoryLoading = false;
      }
    },
    uploadCsv(formData) {
      return this.$_services.categories.uploadCsv(formData);
    },
    downloadTemplateCsv() {
      return this.$_services.categories.fetchCsvTemplateData();
    },
  },
};
</script>

<style lang="scss">
</style>
